import React from "react";
import { Layout } from "antd";
import packageJson from "../../../package.json";

const Footer = () => {
  const { Footer } = Layout;
  return (
    <Footer style={{ 
      textAlign: "center",
      padding: "12px 24px",
      backgroundColor: 'var(--color-footer)',
      color: 'var(--color-text)',
      fontSize: "0.9rem",
      borderRadius: "4px",
      marginTop: "24px"
    }}>
      Powered by Crossover ©{new Date().getFullYear()} Versión {packageJson.version}
    </Footer>
  );
};

export default Footer;
