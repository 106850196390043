import React, { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";

import AuthGuard from "../guards/AuthGuard";
import { Spin } from "antd";
import GuestGuard from "../guards/GuestGuard";
import Layout from "../components/Layout/LayoutPrincipal";
import useAuth from "../hooks/useAuth";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/");

  return (
    <Suspense
      fallback={
        <Spin
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { access } = useAuth();
  return useRoutes([
    {
      path: "login",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: "recuperacion-contrasena",
      element: (
        <GuestGuard>
          <RecuperacionContrasena />
        </GuestGuard>
      ),
    },
    {
      path: "validation",
      element: (
        <GuestGuard>
          <Validate />
        </GuestGuard>
      )
    },
    {
      path: "",
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: access?.includes(13)
            ? <Navigate to="/estado-cargas" replace />
            : <Navigate to="/estado-cargas" replace />
        },
        access?.includes(13) ? { path: "/estado-cargas", element: <MonitoreoCargas /> } : {},
        access?.includes(2) ? { path: "ventas-charts", element: <VentaCharts /> } : {},
        access?.includes(3) ? { path: "ventas", element: <Venta /> } : {},
        access?.includes(5) ? { path: "ot-charts", element: <OtCharts /> } : {},
        access?.includes(6) ? { path: "ot", element: <Ot /> } : {},
        access?.includes(7) ? { path: "ot-osat-report", element: <OtOsatReport /> } : {},
        access?.includes(9) ? { path: "repuestos-charts", element: <RepuestosCharts /> } : {},
        access?.includes(10) ? { path: "repuestos", element: <Repuestos /> } : {},
        access?.includes(11) ? { path: "stock", element: <Stock /> } : {},
        access?.includes(12) ? { path: "users", element: <Users /> } : {},
        access?.includes(18) ? { path: "profiles", element: <Profiles /> } : {},
        access?.includes(19) ? { path: "brands", element: <Brands /> } : {},
        access?.includes(20) ? { path: "dealers", element: <Dealers /> } : {},
        access?.includes(21) ? { path: "notifications", element: <Notifications /> } : {},
        access?.includes(14) ? { path: "file-upload", element: <Cargas /> } : {},
        access?.includes(15) ? { path: "conexion-usuarios", element: <UserSession /> } : {},
        access?.includes(23) ? { path: "updates", element: <Updates /> } : {},
        access?.includes(24) ? {path: "bitacora-cargas", element: <BitacoraCargas />} : {},
        access?.includes(25) ? {path: "bitacora-users", element: <BitacoraUsersCreateLog />} : {},
        access?.includes(26) ? {path: "bitacora-profiles", element: <BitacoraProfileCreateLog />} : {},
        { path: "all-notifications", element: <NotificationsNormalize /> },
        { path: "*", element: access ? <Navigate to="/estado-cargas" replace /> : <Navigate to="/login" replace /> },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ]);
}

const Login = Loadable(lazy(() => import("../components/auth/Login")));
const RecuperacionContrasena = Loadable(lazy(() => import("../components/auth/ChangePasswordForm")));
const Venta = Loadable(lazy(() => import("../components/venta/Venta")));
const VentaCharts = Loadable(lazy(() => import("../components/venta/VentaCharts")));
const Stock = Loadable(lazy(() => import("../components/stock/Stock")));
const Ot = Loadable(lazy(() => import("../components/ot/Ot")));
const OtCharts = Loadable(lazy(() => import("../components/ot/OtCharts")));
const OtOsatReport = Loadable(lazy(() => import("../components/ot/OtOsatReport")));
const RepuestosCharts = Loadable(lazy(() => import("../components/repuestos/RepuestosCharts")));
const Repuestos = Loadable(lazy(() => import("../components/repuestos/Repuestos")));
const Users = Loadable(lazy(() => import("../components/users/Users")));
const Profiles = Loadable(lazy(() => import("../components/profiles/Profiles")));
const Brands = Loadable(lazy(() => import("../components/brands/Brands")));
const Dealers = Loadable(lazy(() => import("../components/dealers/Dealers")));
const Notifications = Loadable(lazy(() => import("../components/notifications/Notifications")));
const MonitoreoCargas = Loadable(lazy(() => import("../components/monitoreo/MonitoreoCargas")));
const Cargas = Loadable(lazy(() => import("../components/file-upload/FileUpload")));
const UserSession = Loadable(lazy(() => import("../components/userSession/userSession.jsx")));
const NotificationsNormalize = Loadable(lazy(() => import("../components/notificationsNormalize/AllNotifications.jsx")));
const Updates = Loadable(lazy(() => import("../components/updates/Updates.jsx")));
const BitacoraCargas = Loadable(lazy(() => import("../components/bitacoraCargas/BitacoraCargas.jsx")))
const BitacoraUsersCreateLog = Loadable(lazy(() => import("../components/bitacoraUsersCreate/BitacoraUsersCreate.jsx")))
const Validate = Loadable(lazy(() => import("../components/auth/ValidateUser.jsx")))
const BitacoraProfileCreateLog = Loadable(lazy(() => import("../components/bitacoraProfileCreate/BitacoraProfileCreate.jsx")));