import React, { useState } from "react";
import { Modal, Form, Input, Button, Upload, message, Progress, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { sendSupportEmail } from "../../redux/slices/emailSupport";
import {
  MailOutlined,
  PaperClipOutlined,
  SendOutlined,
  CloseOutlined,
  UserOutlined
} from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import "./Contacto.css";

const Contacto = ({ visible, onClose }) => {
  const { user } = useAuth();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('nombreUsuario', values.nombreUsuario);
      formData.append('correo', values.correo);
      formData.append('asunto', values.asunto);
      formData.append('mensaje', values.mensaje);

      if (values.archivos && values.archivos.fileList) {
        values.archivos.fileList.forEach(file => {
          formData.append('archivos', file.originFileObj);
        });
      }

      setLoading(true);
      await dispatch(sendSupportEmail(formData, setUploadProgress));
      message.success("Correo enviado exitosamente");
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(error.message || "Error al enviar el correo");
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Nuevo Mensaje"
      open={visible}
      type="primary"
      onCancel={handleCancel}
      footer={null}
      className="email-support-modal"
      width={500}
      style={{ top: 40, marginBottom: 10 }}
      closeIcon={<CloseOutlined className="text-white" />}
    >
      <div className="email-form-container">
        <div className="email-header">
          <MailOutlined className="email-header-icon" />
          <h2>Contactar a Soporte</h2>
        </div>
        
        <Form 
          form={form} 
          layout="vertical" 
          initialValues={{ 
            nombreUsuario: user?.name, 
            correo: user?.email 
          }}
          className="email-form"
        >
          <div className="form-grid">
            <Form.Item 
              name="nombreUsuario" 
              label="De:"
              className="form-item-half"
            >
              <Input 
                disabled 
                prefix={<UserOutlined className="text-gray-400" />}
              />
            </Form.Item>
            
            <Form.Item 
              name="correo" 
              label="Correo de Contacto:"
              className="form-item-half"
            >
              <Input 
                disabled
                prefix={<MailOutlined className="text-gray-400" />}
              />
            </Form.Item>
            
            <Form.Item 
              name="asunto" 
              label="Asunto:"
              rules={[{ required: true, message: "Por favor ingrese el asunto" }]}
              className="form-item-full"
            >
              <Input 
                placeholder="Escriba el asunto de su mensaje" 
                autoComplete="off"
              />
            </Form.Item>
            
            <Form.Item 
              name="mensaje" 
              label="Mensaje:"
              rules={[{ required: true, message: "Por favor ingrese el mensaje" }]}
              className="form-item-full"
            >
              <Input.TextArea 
                rows={6}
                placeholder="Escriba su mensaje aquí..."
                style={{ resize: 'none' }}
              />
            </Form.Item>
            
            <Form.Item 
              name="archivos" 
              label="Adjuntos:"
              className="form-item-full"
            >
              <div className="upload-section">
                <Upload
                  beforeUpload={() => false}
                  multiple
                  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.sql,.txt,.csv"
                  onChange={({ fileList }) => form.setFieldsValue({ archivos: { fileList } })}
                >
                  <Tooltip title="Formatos permitidos (MÁX 50MB): JPG, PNG, PDF, DOC, DOCX, SQL, TXT, CSV.">
                    <Button className="button-attach" icon={<PaperClipOutlined />}>
                      Adjuntar Archivos
                    </Button>
                  </Tooltip>
                </Upload>
              </div>
            </Form.Item>
          </div>

          {loading && (
            <div className="progress-indicator">
              <Progress percent={uploadProgress} status="active" />
            </div>
          )}

          <div className="footer-actions">
            <Button onClick={onClose}>
              Cancelar
            </Button>
            <Button 
              type="primary" 
              loading={loading} 
              onClick={handleOk}
              icon={<SendOutlined />}
            >
              Enviar Mensaje
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default Contacto;