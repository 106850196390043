import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Modal, Tooltip, notification } from "antd";
import { InfoCircleOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { FirstChangePasswor } from "../../redux/slices/auth"
import './FirstTimeChangePassword.css'

const FirstTimeChangePassword = ({ onClose, setSuccessMessage }) => {
    const [alert, setAlert] = useState("");
    const [visible, setVisible] = useState(false);
    const [passwordUser, setPasswordUser] = useState({
        newPassword: "",
        repeatPassword: "",
    });

    const navigate = useNavigate();
    const { newPassword, repeatPassword } = passwordUser;

    const { user, logout } = useAuth();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const modalTitle = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <Tooltip title={"Se requiere un cambio de contraseña para el primer inicio de sesión"} placement="bottom">
                <InfoCircleOutlined
                    style={{
                        marginRight: "8px",
                        fontSize: "1em",
                        verticalAlign: "middle",
                    }}
                />
            </Tooltip>
            <h3 style={{ margin: 0 }}>Cambio de contraseña obligatorio</h3>
        </div>
    )

    const onFinish = async () => {
        if (newPassword.trim() === "" || repeatPassword.trim() === "") {
            setVisible(true);
            setAlert("Debe ingresar ambas contraseñas");
            return;
        }
        if (newPassword !== repeatPassword) {
            setVisible(true);
            setAlert("Las contraseñas no son iguales");
            return;
        }
        const data = {
            newPassword,
        };

        try {
            await dispatch(FirstChangePasswor({ data }));
            setSuccessMessage(1);

            setTimeout(() => {
                onClose();
                logout();
                navigate('/login');
            }, 1000);

        } catch (e) {
            setVisible(true);
            setSuccessMessage(2);
        }
    };

    useEffect(() => {
        if (user?.name) {
            notification.success({
                message: `¡Bienvenido al portal SIC, ${user.name}!`,
                description: "Por favor, cambia tu contraseña para continuar.",
                placement: "topRight",
            });
        }
    }, [user]);

    return (
        <>
            <div className="overlay"></div>

            <Modal
                title={modalTitle}
                open={true}
                maskClosable={false}
                footer={null}
                centered
                closable={false}
                style={{
                    borderRadius: '16px',
                    overflow: 'hidden',
                }}
                bodyStyle={{
                    borderRadius: '16px',
                }}
            >
                <Form
                    name="normal_login"
                    style={{
                        width: "100%",
                        maxWidth: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    form={form}
                >

                    <h4>{user?.name?.toUpperCase()}</h4>

                    <Form.Item
                        name="newPassword"
                        rules={[{ required: true, message: 'Contraseña es requerida!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Nueva contraseña"
                            name="newPassword"
                            value={passwordUser.newPassword}
                            onChange={(e) =>
                                setPasswordUser({ ...passwordUser, newPassword: e.target.value })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name="repeatPassword"
                        rules={[{ required: true, message: 'Contraseña es requerida!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Confirmar contraseña"
                            name="repeatPassword"
                            value={passwordUser.repeatPassword}
                            onChange={(e) =>
                                setPasswordUser({ ...passwordUser, repeatPassword: e.target.value })
                            }
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            style={{
                                width: "100%",
                                background: "#F9A742",
                                color: "black"
                            }}
                            onClick={onFinish}
                        >
                            Guardar Cambios
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        {visible && (
                            <Alert
                                message={alert}
                                type="error"
                                style={{ marginBottom: "16px" }}
                                closable
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
};

export default FirstTimeChangePassword;
