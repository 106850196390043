import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    Allbrands: [],
    configNotifications: [],
    userNotification: [],
    status: "",
    message: "",
  },
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload.data.message;
    },
    userNotification(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        dealerContactsInfo: action.payload.dealerContactsInfo.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
      
    },
    brands(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        Allbrands: action.payload.brands.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    notifications(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        configNotifications: action.payload.configNotifications.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    sendMessage(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.data.status,
        message: action.payload.data.message,
      };
    },
    resetMessage(state, action) {
      state.data = {
        ...state.data,
        status: "",
        message: "",
      };
    },
  },
});

export default slice.reducer;

export function getBrands({ filters = {}, fromNotifications = false }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/brands/all?`;
      let count = 0;

      if (fromNotifications) {
        url += `active=1`;
        count++;
      }

      const sanitizedFilters = { ...filters };
      if (fromNotifications) {
        delete sanitizedFilters.active;
      }

      for (const property in filters) {
        url +=
          count == 0
            ? `${property}=${filters[property]}`
            : `&${property}=${filters[property]}`;
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brands(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConfigNotification(idDealer = "", idBrand = "") {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const filters = { idDealer, idBrand };
    try {
      let url = `/notifications/config?`;
      let count = 0;
      for (const property in filters) {
        url +=
          count == 0
            ? `${property}=${filters[property]}`
            : `&${property}=${filters[property]}`;
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.notifications(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateNotification = 
  ({data}) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/notifications/config`, data);
    if (response && response.data) {
      return Promise.resolve(response.data);
    } else {
      throw Promise.reject(new Error("Invalid response format."));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};


export function createUser(user) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {        
        let url = `/notifications/addUser`;
        const response = await axios.post(url, user);
        if (response && response.data) {
          return Promise.resolve(response.data);
        } else {
          throw Promise.reject(new Error("Invalid response format."));
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function getDealerContactList(idDealer = "") {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/notifications/dealerContact/list?idDealer=${idDealer}`;
      const response = await axios.get(url);
      dispatch(slice.actions.userNotification(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateDealerContactList = 
  ({data}) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`/notifications/dealerContact/listUpdate`, data);
    if (response && response.data) {
      return Promise.resolve(response.data);
    } else {
      throw Promise.reject(new Error("Invalid response format."));
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
