import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './JWTContext';
import axios from '../utils/axios';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { user, updateUserContext, refreshInfoToken } = useContext(AuthContext);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (user?.theme_preference) {
      setTheme(user.theme_preference);
    }
  }, [user]);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = async () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    try {
      await axios.put('/user/theme', { theme_preference: newTheme });
      await refreshInfoToken();
    } catch (error) {
      console.error('Error al actualizar la preferencia de tema:', error);
      setTheme(theme === 'light' ? 'dark' : 'light');
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;