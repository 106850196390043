import axios from 'axios';
import { config } from '../config/config';
import { notification } from "antd";

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
});

export const axiosInstanceLogin = axios.create({
  baseURL: config.baseUrl,
});

let isRefreshing = false;
let failedQueue = [];
let sessionExpiredNotificationShown = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expTime = payload.exp * 1000;
    return Date.now() >= expTime;
  } catch (error) {
    return true;
  }
};

const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const refreshToken = localStorage.getItem("tokenRefresh");
    if (!refreshToken) {
      throw new Error("No hay refresh token");
    }

    const { data } = await axios.post(`${config.baseUrl}/refresh`, { tokenRefresh: refreshToken }, { headers: { "Content-Type": "application/json" } });

    localStorage.setItem("accessToken", data.token);
    localStorage.setItem("tokenRefresh", data.tokenRefresh);
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

    processQueue(null, data.token);
    return data.token;
  } catch (error) {
    processQueue(error, null);
    throw error;
  } finally {
    isRefreshing = false;
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.url === "/user/theme") {
      return config;
    }

    let token = localStorage.getItem("accessToken");
    const refreshTokenValue = localStorage.getItem("tokenRefresh");

    if (!token || isTokenExpired(token)) {
      return Promise.reject({ response: { status: 401 } });
    }

    if (refreshTokenValue && !isTokenExpired(refreshTokenValue)) {
      try {
        token = await refreshToken();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const showSessionExpiredNotification = () => {
  if (sessionExpiredNotificationShown) return;

  sessionExpiredNotificationShown = true;

  notification.warning({
    message: "Sesión expirada",
    description: "Tu sesión ha expirado por seguridad. Serás redirigido al login.",
    placement: "topRight",
    duration: 2,
  });

  setTimeout(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenRefresh");
    window.location.href = "/login";
  }, 2000);
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      showSessionExpiredNotification();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;