import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {},
  estadosOT: [],
  parametros: {},
};

const slice = createSlice({
  name: "ot",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    ot(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ot: action.payload.ot.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    report(state) {
      state.loading = false;
      state.report = true;
    },
    osatreport(state) {
      state.loading = false;
      state.report = true;
    },
    year(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        years: action.payload.years.map((x) => {
          return { ...x, key: x.year };
        }),
      };
    },
    branchoffice(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        branchoffices: action.payload.branchoffices.map((x) => {
          return { ...x, key: x.branchoffice };
        }),
      };
    },
    dealer(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        dealers: action.payload.dealers.map((x) => {
          return { ...x, key: x.dealer };
        }),
      };
    },

    dealersview(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        dealers: action.payload.dealers.map((x) => {
          return { ...x, key: x.dealer };
        }),
      };
    },

    brand(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        brandsF: action.payload.brandsF.map((x) => {
          return { ...x, id_marca: x.id_marca, key: x.value };
        }),
      };
    },
    brandsview(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        brandsF: action.payload.brandsF.map((x) => {
          return { ...x, id_marca: x.id_marca, key: x.value };
        }),
      }
    },
    dashboard(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.filters_data,
        dashboardItems: action.payload.dashboard
      };
    },
    startLoadingEstados(state) {
      state.loading = true;
    },
    setEstadosOT(state, action) {
      state.loading = false;
      state.estadosOT = action.payload;
    },
    estadosError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setParametros(state, action) {
      state.loading = false;
      state.parametros[action.payload.categoria] = action.payload.parametros;
    },
  },
});

export default slice.reducer;

export function getOt({ page = 1, count = 10, filters = {}, orderField = {}, orderSort }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot?page=${page}&count=${count}`;
      for (const property in filters) {
        url += `&${property}=${filters[property]}`;
      }

      if (orderField && orderSort) {
        for (const field in orderField) {
          const fieldValue = orderField[field];
          const orderValue = orderSort;
          if (fieldValue !== 0) {
            url += `&orderField=${field}&orderSort=${orderValue}`;
          }
        }
      }

      const response = await axios.get(url);
      dispatch(slice.actions.ot(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboard({ year = '', month = '', branchoffice = '', dealer = '', bussinesId = '', brands = '', dealersO = '', brand = '' }) {
  // const filters = {year,month,branchoffice,dealer,brands}
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/dashboard?ano_facturacion=${year}&mes_facturacion=${month}&nombre_sucursal=${branchoffice}&nombre_dealer=${dealer}&bussinesId=${bussinesId}&brands=${brands}&dealersO=${dealersO}&brand=${brand}`;
      const response = await axios.get(url);
      dispatch(slice.actions.dashboard(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReport({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/report?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
        count++;
      }
      const result = await axios.get(url, { responseType: "blob" });
      fileDownload(result.data, `ot_${new Date().getTime()}.xlsx`);
      dispatch(slice.actions.report());
    } catch (error) {
      error.response.data.text().then(text => {
        dispatch(slice.actions.hasError({ message: text }));
      }).catch(() => {
        dispatch(slice.actions.hasError({ message: 'Failed to download the report' }));
      });
    }
  };
}


export function getOsatReport({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/osat-report?`;
      let count = 0;
      for (const key in filters) {
        url +=
          count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
        count++;
      }
      const result = await axios.get(url, { responseType: "blob" });
      fileDownload(result.data, `OSAT_${filters['dealer']}_${filters['fromdate']}_${filters['todate']}.xlsx`);
      dispatch(slice.actions.osatreport());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYears({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/years?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.year(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOfficesVW({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/branchofficesVW?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOffices({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/branchoffices?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getDealers({ brands = '', dealersO = '', osat = '' }) {
  const filters = { brands, dealersO, osat }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/dealers?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDealersView({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/dealersview?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrands({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/brands?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandsView({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/ot/brandsVW?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEstadosOT() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingEstados());
    try {
      const response = await axios.get('/ot/estados_ot');
      dispatch(slice.actions.setEstadosOT(response.data));
    } catch (error) {
      dispatch(slice.actions.estadosError(error.toString()));
    }
  };
}

export function getParametros(categoria) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ot/parametros`);
      dispatch(slice.actions.setParametros({ categoria, parametros: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}