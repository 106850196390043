import React, { useState } from "react";

import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import packageJson from "../../../package.json";

const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);

  const showForgotPassword = () => {
    setForgotPassword(true);
  };

  const showLogin = () => {
    setForgotPassword(false);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#001529",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px"
        }}
      >
        {forgotPassword ? (
          <ForgotPassword showLogin={showLogin} version={packageJson.version} />
        ) : (
          <LoginForm
            showForgotPassword={showForgotPassword}
            version={packageJson.version}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
